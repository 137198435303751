<template>
    <div class="details">
        <div class="title">
            <div>
                <p>
                    <span>购买产品</span>
                    <span>{{ product() }}</span>
                </p>
                <p v-if="data.productCategory == 1">
                    <span>CorpId</span>
                    <span>{{ data.corpId ? data.corpId : '- -' }}</span>
                </p>
                <p v-if="data.productCategory == 1">
                    <span>试用状态</span>
                    <span>{{ tencentTrialStatus() }}</span>
                </p>
            </div>
            <div>
                <p v-if="data.productCategory == 1">
                    <span>用户数</span>
                    <span>{{ orderNumber() }}</span>
                </p>
                <p v-if="data.productCategory == 1">
                    <span>年限</span>
                    <span>{{ orderTerm() }}</span>
                </p>
                <p v-if="data.productCategory == 1">
                    <span>产品状态</span>
                    <span>{{ statusFormatter() }}</span>
                </p>
            </div>
            <div>
                <p v-if="data.productCategory == 1">
                    <span>订单开始时间</span>
                    <span>{{
                        createTimeformatter() ? createTimeformatter() : ' - -'
                    }}</span>
                </p>
                <p v-if="data.productCategory == 1">
                    <span>订单到期时间</span>
                    <span>{{
                        expireTimeFormatter() ? expireTimeFormatter() : ' - -'
                    }}</span>
                </p>
            </div>
            <div>
                <p v-if="data.productCategory == 1">
                    <span>产品开始时间</span>
                    <span>
                        {{
                            startServiceTimeformatter()
                                ? startServiceTimeformatter()
                                : ' - -'
                        }}
                    </span>
                </p>
                <p v-if="data.productCategory == 1">
                    <span>产品到期时间</span>
                    <span>{{
                        endServiceTimeFormatter()
                            ? endServiceTimeFormatter()
                            : ' - -'
                    }}</span>
                </p>
            </div>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>
            <el-table-column
                label="开始时间"
                show-overflow-tooltip
                prop="createTime"
            >
            </el-table-column>
            <el-table-column
                label="订单编号"
                show-overflow-tooltip
                prop="orderNo"
            >
            </el-table-column>
            <el-table-column
                label="订单类型"
                v-if="data.productCategory == 1"
                show-overflow-tooltip
                :formatter="orderType"
            >
            </el-table-column>
            <el-table-column
                label="订单金额"
                show-overflow-tooltip
                prop="amount"
            >
            </el-table-column>
            <el-table-column
                label="实收金额"
                show-overflow-tooltip
                prop="actualAmount"
            >
            </el-table-column>
            <el-table-column
                label="原价"
                show-overflow-tooltip
                prop="formerAmount"
            >
            </el-table-column>

            <el-table-column
                prop="expireTime"
                label="到期时间"
                show-overflow-tooltip
                v-if="data.productCategory == 1"
            >
            </el-table-column>

            <el-table-column
                label="交付状态"
                show-overflow-tooltip
                :formatter="orderStatus"
            >
            </el-table-column>
            <el-table-column label="备注" show-overflow-tooltip prop="remark">
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display:block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: '',
            tableData: [],
        };
    },
    methods: {
        getData(row) {
            // console.log(row);
            this.data = row;
            this.tableData = row.orderBOList;
        },
        tencentTrialStatus() {
            return this.data.tencentTrialStatus == 1
                ? '未试用'
                : this.data.tencentTrialStatus == 2
                ? '申请试用'
                : this.data.tencentTrialStatus == 3
                ? '试用中'
                : this.data.tencentTrialStatus == 4
                ? '试用到期'
                : this.data.tencentTrialStatus == 5
                ? '试用结束'
                : '- -';
        },
        product() {
            let orderBOList = this.data.orderBOList;
            if (orderBOList && orderBOList.length > 0) {
                return this.$tableDataHandle.productCategory(
                    orderBOList[0].productCategory
                );
            }
            return this.$tableDataHandle.productCategory(
                this.data.productCategory
            );
        },

        // 用户数
        orderNumber() {
            let orderBOList = this.data.orderBOList;
            if (orderBOList && orderBOList.length > 0) {
                var orderNumberTemp = 0;
                for (let i = 0; i < orderBOList.length; i++) {
                    if (orderBOList[i].productCategory == 1) {
                        if (orderBOList[i].orderType == 3) {
                            orderNumberTemp =
                                orderNumberTemp + orderBOList[i].orderNumber;
                        } else {
                            if (orderNumberTemp != 0) {
                                orderNumberTemp =
                                    orderNumberTemp +
                                    orderBOList[i].orderNumber;
                            } else {
                                orderNumberTemp = orderBOList[i].orderNumber;
                            }
                            break;
                        }
                    }
                }
                return orderNumberTemp;
            }
            return this.data.orderNumber;
        },
        // 年限
        orderTerm() {
            let orderBOList = this.data.orderBOList;
            if (orderBOList && orderBOList.length > 0) {
                var orderTermTemp;
                for (let i = 0; i < orderBOList.length; i++) {
                    if (orderBOList[i].productCategory == 1) {
                        if (orderBOList[i].orderType != 3) {
                            orderTermTemp = orderBOList[i].orderTerm;
                            break;
                        }
                    } else {
                        orderTermTemp = orderBOList[i].orderTerm;
                        break;
                    }
                }
                if (!orderTermTemp) {
                    return orderBOList[0].orderTerm;
                }
                return orderTermTemp;
            }
            return this.data.orderTerm;
        },
        createTimeformatter() {
            let orderBOList = this.data.orderBOList;
            if (orderBOList && orderBOList.length > 0) {
                var createTimeTemp;
                for (let i = 0; i < orderBOList.length; i++) {
                    if (orderBOList[i].productCategory == 1) {
                        if (orderBOList[i].orderType != 3) {
                            createTimeTemp = orderBOList[i].createTime;
                            break;
                        }
                    } else {
                        createTimeTemp = orderBOList[i].createTime;
                        break;
                    }
                }
                if (!createTimeTemp) {
                    return orderBOList[0].createTime;
                }
                return createTimeTemp;
            }
            return this.data.createTime;
        },
        expireTimeFormatter() {
            var orderBOList = this.data.orderBOList;
            if (orderBOList && orderBOList.length > 0) {
                var expireTimeTemp;
                for (let i = 0; i < orderBOList.length; i++) {
                    if (orderBOList[i].productCategory == 1) {
                        if (orderBOList[i].orderType != 3) {
                            expireTimeTemp = orderBOList[i].expireTime;
                            break;
                        }
                    } else {
                        expireTimeTemp = orderBOList[i].expireTime;
                        break;
                    }
                }
                if (!expireTimeTemp) {
                    return orderBOList[0].expireTime;
                }
                return expireTimeTemp;
            }
            return this.data.expireTime;
        },
        startServiceTimeformatter() {
            let orderBOList = this.data.orderBOList;

            if (orderBOList && orderBOList.length > 0) {
                var createTimeTemp;
                for (let i = 0; i < orderBOList.length; i++) {
                    if (orderBOList[i].productCategory == 1) {
                        if (orderBOList[i].orderType != 3) {
                            createTimeTemp = orderBOList[i].startServiceTime;
                            break;
                        }
                    } else {
                        createTimeTemp = orderBOList[i].startServiceTime;
                        break;
                    }
                }
                if (!createTimeTemp) {
                    return orderBOList[0].startServiceTime;
                }
                return createTimeTemp;
            }
            return this.data.startServiceTime;
        },
        endServiceTimeFormatter() {
            var orderBOList = this.data.orderBOList;

            if (orderBOList && orderBOList.length > 0) {
                var expireTimeTemp;
                for (let i = 0; i < orderBOList.length; i++) {
                    if (orderBOList[i].productCategory == 1) {
                        if (orderBOList[i].orderType != 3) {
                            expireTimeTemp = orderBOList[i].endServiceTime;
                            break;
                        }
                    } else {
                        expireTimeTemp = orderBOList[i].endServiceTime;
                        break;
                    }
                }
                if (!expireTimeTemp) {
                    return orderBOList[0].endServiceTime;
                }
                return expireTimeTemp;
            }
            return this.data.endServiceTime;
        },
        statusFormatter() {
            let notPayed = this.data.notPayed;
            let payed = this.data.payed;
            let statusTotal = this.data.statusTotal;
            if (statusTotal == notPayed) {
                return '未交付';
            }
            if (statusTotal == payed) {
                return '已交付';
            }
            return '部分交付';
        },
        orderStatus(row) {
            if (row.status == 1) {
                return '未交付';
            } else if (row.status == 2) {
                return '部分交付';
            } else {
                return '已交付';
            }
        },
        orderType(row) {
            if (row.productCategory == 1) {
                if (row.orderType == 1) {
                    return '新增';
                } else if (row.orderType == 2) {
                    return '续费';
                } else {
                    return '扩用户';
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        padding: 0 20px;
        div {
            flex: 1;
            font-size: 14px;
            color: #333;
            p {
                padding: 10px 0;
                display: flex;
                align-items: center;
                span {
                    display: inline-block;
                }
                span:nth-child(1) {
                    width: 90px;
                }
                span:nth-child(2) {
                    max-width: 150px;
                    overflow: hidden;
                    /*文本不会换行*/
                    white-space: nowrap;
                    /*当文本溢出包含元素时，以省略号表示超出的文本*/
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .table-box {
        padding: 10px;
    }
}
</style>
