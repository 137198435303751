<template>
  <div class="customer">
    <div class="search-box">
      <div class="search-one">
        <p class="left">
          <span>筛选字段</span>
          <el-select
            v-model="field"
            size="small"
            style="width:100px"
            clearable
            placeholder="请选择"
          >
            <el-option label="公司名称" value="1"></el-option>
            <el-option label="联系手机号" value="2"></el-option>
            <el-option label="联系人" value="3"></el-option>
            <el-option label="域名" value="4"></el-option>
          </el-select>

          <el-input
            size="small"
            style="width:150px;margin-left:16px"
            placeholder="请输入内容"
            v-model.trim="fieldVal"
            clearable
          >
          </el-input>
          <el-button
            @click="getTableData"
            type="primary"
            size="small"
            icon="el-icon-search"
          ></el-button>
          <span>到期时间</span>
          <el-radio-group v-model="createTime" size="small">
            <el-radio-button
              label="1个月内"
              @click.native.prevent="createitem('1个月内')"
            ></el-radio-button>
            <el-radio-button
              label="3个月内"
              @click.native.prevent="createitem('3个月内')"
            ></el-radio-button>
            <el-radio-button
              label="1年内"
              @click.native.prevent="createitem('1年内')"
            ></el-radio-button>
          </el-radio-group>
          <el-date-picker
            size="small"
            v-model="createDate"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width:240px;margin-left:19px"
            clearable
            @change="expireTime"
          >
          </el-date-picker>
        </p>
        <p>
          <el-button
            type="primary"
            style="margin-right:16px; border-color: #2370EB;background:#2370EB"
            size="small"
            @click="getTableData"
            >查询</el-button
          >
          <el-button
            @click="searchEmpty"
            type="primary"
            style="margin:0;background:none;border-color:#D9D9D9;color:#333333"
            icon="el-icon-refresh-left"
            size="small"
            >重置</el-button
          >
          <el-button
            v-show="fold"
            @click="fold = !fold"
            type="text"
            style="margin-right:29px;color:#2162CB"
          >
            收起 <i class="el-icon-arrow-up"></i>
          </el-button>
          <el-button
            v-show="!fold"
            @click="fold = !fold"
            type="text"
            style="margin-right:29px;color:#2162CB"
          >
            展开 <i class="el-icon-arrow-down"></i>
          </el-button>
        </p>
      </div>
      <div class="search-two" v-show="fold">
        <span>开始时间</span>
        <el-radio-group v-model="followTime" size="small">
          <el-radio-button
            label="本月"
            @click.native.prevent="clickFollow('本月')"
          ></el-radio-button>
          <el-radio-button
            label="上月"
            @click.native.prevent="clickFollow('上月')"
          ></el-radio-button>
          <el-radio-button
            label="去年"
            @click.native.prevent="clickFollow('去年')"
          ></el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="small"
          v-model="followDate"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width:240px;margin-left:19px"
          clearable
          @change="expireTime"
        >
        </el-date-picker>
        <span>购买产品</span>
        <el-select
          v-model="productCategory"
          size="small"
          style="width:200px;margin-left:10px"
          clearable
          placeholder="请选择"
          @change="getTableData()"
          ><el-option label="全部" value="0"></el-option>
          <el-option label="腾讯企业邮箱" value="1"></el-option>
          <el-option label="阿里企业邮箱" value="2"></el-option>
          <el-option label="云产品" value="3"></el-option>
          <el-option label="网易企业邮箱" value="4"></el-option>
          <el-option label="网站" value="5"></el-option>
          <el-option label="投屏盒子" value="6"></el-option>
          <el-option label="微盘" value="7"></el-option>
          <el-option label="主机" value="8"></el-option>
          <el-option label="服务器" value="9"></el-option>
          <el-option label="会话存档" value="10"></el-option>
          <el-option label="企业微信" value="11"></el-option>
        </el-select>
        <span v-show="btnP.searchUser">成员</span>
        <el-select
          v-model="adminId"
          size="small"
          style="width:180px"
          clearable
          placeholder="请选择"
          @change="getTableData()"
          v-show="btnP.searchUser"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <p style="margin-top:14px">
          <span>订单状态</span>
          <el-radio-group v-model="orderStatus" size="small">
            <el-radio-button
              label="未交付"
              @click.native.prevent="clickStatus('未交付')"
            ></el-radio-button>
            <el-radio-button
              label="部分交付"
              @click.native.prevent="clickStatus('部分交付')"
            ></el-radio-button>
            <el-radio-button
              label="已交付"
              @click.native.prevent="clickStatus('已交付')"
            ></el-radio-button>
          </el-radio-group>
          <span v-show="btnP.depSearch">部门</span>
          <el-select
            v-model="dempVal"
            size="small"
            v-show="btnP.depSearch"
            style="width:170px;"
            clearable
            placeholder="请选择"
            @change="getTableData()"
          >
            <el-option
              v-for="item in dempData"
              :key="item.id"
              :label="item.departmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </p>
      </div>
    </div>
    <div class="table-title">
      <div style="display:flex">
        <p>
          应收 {{ statistics.orderTotalAmount }}（{{
            statistics.orderTotal
          }}单）
        </p>
        <p style="margin-left:64px">
          实收
          <span>
            {{ statistics.actualTotalAmount }}（{{ statistics.orderTotal }}单）
          </span>
        </p>
        <p style="margin-left:64px">
          原价 {{ statistics.formerTotalAmount }}（{{
            statistics.orderTotal
          }}单）
        </p>
      </div>
      <span> </span>
    </div>
    <el-table
      class="table-box"
      ref="multipleTable"
      :data="tableData"
      height="100%"
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#333333',
        'font-size': '14px',
        'font-weight': '500',
      }"
    >
      <el-table-column width="10"> </el-table-column>
      <el-table-column
        label="开始时间"
        :formatter="createTimeformatter"
        show-overflow-tooltip
        v-if="productCategory == 1"
      >
      </el-table-column>
      <el-table-column
        label="公司名称"
        min-width="150"
        :formatter="conpanyFormatter"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="购买产品"
        show-overflow-tooltip
        :formatter="product"
      >
      </el-table-column>
      <el-table-column
        label="域名"
        show-overflow-tooltip
        v-if="productCategory == 1"
      >
      </el-table-column>
      <el-table-column
        label="订单金额"
        show-overflow-tooltip
        :formatter="amount"
      >
      </el-table-column>
      <el-table-column
        label="实收金额"
        show-overflow-tooltip
        :formatter="actualAmount"
      >
      </el-table-column>
      <el-table-column
        prop=""
        label="原价"
        show-overflow-tooltip
        min-width="110"
        :formatter="formerAmount"
      >
      </el-table-column>
      <el-table-column
        label="用户数"
        show-overflow-tooltip
        :formatter="orderNumber"
        v-if="productCategory == 1"
      >
      </el-table-column>
      <el-table-column
        label="年限"
        show-overflow-tooltip
        v-if="productCategory == 1"
        :formatter="orderTerm"
      >
      </el-table-column>
      <el-table-column
        prop="expireTime"
        label="到期时间"
        :formatter="expireTimeFormatter"
        show-overflow-tooltip
        v-if="productCategory == 1"
      >
      </el-table-column>

      <el-table-column
        label="订单状态"
        show-overflow-tooltip
        :formatter="statusFormatter"
      >
      </el-table-column>
      <el-table-column label="员工" prop="adminName" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="订单详情" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button
            v-show="btnP.closedDetails"
            type="text"
            style="color:#2370EB"
            @click="onDetails(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>

      <div slot="empty" class="empty">
        <img src="@/assets/zanwushuju.png" />
        <span style="display:block">暂无数据～</span>
      </div>
    </el-table>
    <div class="page-box">
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        :page-sizes="[10, 20, 50]"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 详情 -->
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleDetails"
      size="1080px"
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
        >
          客户详情
        </div>
      </template>
      <Details @close="handleDetails" ref="details" />
    </el-drawer>
  </div>
</template>
<script>
import { payedList, topBrief } from "@/api/user/ae/closed";
import { roleList } from "@/api/customer/customer";
import Details from "./details.vue";
import { departmentList } from "@/api/admin/framework/framework.js";
export default {
  components: {
    Details,
  },
  data() {
    return {
      fold: true,
      field: "1",
      fieldVal: "",
      createTime: "",
      createDate: [],
      followTime: "",
      followDate: [],
      productCategory: "0",
      tableData: [],
      orderStatus: "",
      pagesize: 20,
      total: 0,
      currentPage: 1,
      drawer: false,
      direction: "rtl",
      statistics: "",
      userList: [],
      adminId: "",
      btnP: {},
      dempData: [],
      dempVal: "",
    };
  },
  mounted() {
    if (this.$route.query.productCategory) {
      this.productCategory = this.$route.query.productCategory;
    }
    if (this.$route.query.adminId) {
      this.adminId = Number(this.$route.query.adminId);
    } else {
      this.adminId = Number(sessionStorage.getItem("adminId"));
    }
    this.getBtn();
  },
  methods: {
    // 获取按钮权限
    getBtn() {
      var btnArr = JSON.parse(sessionStorage.getItem("isButArr"));
      let btn = {};
      for (let i in btnArr) {
        btn["" + btnArr[i].route] = true;
      }
      this.btnP = btn;
      if (btn.searchUser) {
        this.getUserList();
      }
      if (btn.depSearch) {
        this.getDempData();
      }
      this.getTableData();
    },
    getDempData() {
      let data = {
        param: {},
      };
      departmentList(data).then((res) => {
        this.dempData = res.data;
      });
    },
    getUserList() {
      let data = {
        param: {
          departmentId: sessionStorage.getItem("departmentId"),
          roleTypeList: [1, 2, 3, 4, 8, 9, 10],
        },
      };
      roleList(data).then((res) => {
        this.userList = res.data;
        this.getTableData();
      });
    },
    getStatistics(data) {
      topBrief(data).then((res) => {
        this.statistics = res.data;
      });
    },
    getTableData() {
      let data = {
        param: {
          departmentId: sessionStorage.getItem("departmentId"),
        },
        pageNum: this.currentPage,
        pageSize: this.pagesize,
      };
      if (this.btnP.searchUser) {
        data.param.departmentId = sessionStorage.getItem("departmentId");
      } else {
        data.param.adminId = sessionStorage.getItem("adminId");
      }
      if (this.fieldVal != "") {
        if (this.field == 1) {
          data.param.companyName = this.fieldVal;
        } else if (this.field == 2) {
          data.param.contactPhone = this.fieldVal;
        } else if (this.field == 3) {
          data.param.contactName = this.fieldVal;
        } else if (this.field == 4) {
          data.param.companyDomain = this.fieldVal;
        }
      }
      if (this.createTime != "") {
        let date = new Date();
        this.createDate = [];
        data.param.endExpireDate = this.$searchTime.getNextDate(date, 0);
        if (this.createTime == "1个月内") {
          data.param.startExpireDate = this.$searchTime.getNextDate(date, 30);
        } else if (this.createTime == "3个月内") {
          data.param.startExpireDate = this.$searchTime.getNextDate(date, 60);
        } else if (this.createTime == "1年内") {
          data.param.startExpireDate = this.$searchTime.getNextDate(date, 365);
        }
      }
      if (this.followTime != "") {
        let date = new Date();
        this.followDate = [];
        if (this.followTime == "本月") {
          data.param.endCreateDate = this.$searchTime.thisMonth()[1];
          data.param.startCreateDate = this.$searchTime.thisMonth()[0];
        } else if (this.followTime == "上月") {
          data.param.endCreateDate = this.$searchTime.getLastMonthEndDate();
          data.param.startCreateDate = this.$searchTime.getMonthStartDate();
        } else if (this.followTime == "去年") {
          data.param.endCreateDate = date.getFullYear() - 1 + "-12-31";
          data.param.startCreateDate = date.getFullYear() - 1 + "-01-01";
        }
      }
      if (this.orderStatus != "") {
        if (this.orderStatus == "未交付") {
          data.param.status = 1;
        } else if (this.orderStatus == "部分交付") {
          data.param.status = 2;
        } else if (this.orderStatus == "已交付") {
          data.param.status = 3;
        }
      }
      if (this.productCategory != "" && this.productCategory != 0) {
        data.param.productCategory = this.productCategory;
      }

      if (this.createDate && this.createDate.length > 0) {
        data.param.startExpireDate = this.createDate[0];
        data.param.endExpireDate = this.createDate[1];
      }
      if (this.followDate && this.followDate.length > 0) {
        data.param.startCreateDate = this.followDate[0];
        data.param.endCreateDate = this.followDate[1];
      }
      if (this.adminId != "") {
        data.param.adminId = this.adminId;
      }
      if (this.dempVal != "") {
        data.param.departmentId = this.dempVal;
      }
      payedList(data).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
        this.getStatistics(data);
      });
    },
    // 创建时间点击
    createitem(e) {
      e === this.createTime ? (this.createTime = "") : (this.createTime = e);
      this.getTableData();
    },
    // 跟进时间点击
    clickFollow(e) {
      e === this.followTime ? (this.followTime = "") : (this.followTime = e);
      this.getTableData();
    },
    clickStatus(e) {
      e === this.orderStatus ? (this.orderStatus = "") : (this.orderStatus = e);
      this.getTableData();
    },
    // 时间输入框变化
    expireTime() {
      if (this.createDate && this.createDate.length > 0) {
        this.createTime = "";
      }
      if (this.followDate && this.followDate.length > 0) {
        this.followTime = "";
      }
      this.getTableData();
    },

    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.getTableData();
    },

    createTimeformatter(row) {
      let orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var createTimeTemp;
        for (let i = 0; i < orderBOList.length; i++) {
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType != 3) {
              createTimeTemp = orderBOList[i].createTime;
              break;
            }
          } else {
            createTimeTemp = orderBOList[i].createTime;
            break;
          }
        }
        if (!createTimeTemp) {
          return orderBOList[0].createTime;
        }
        return createTimeTemp;
      }
      return row.createTime;
    },
    conpanyFormatter(row) {
      let orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        return orderBOList[0].companyName;
      }
      return row.companyName;
    },
    // 购买产品
    product(row) {
      let orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        return this.$tableDataHandle.productCategory(
          orderBOList[0].productCategory
        );
      }
      return this.$tableDataHandle.productCategory(row.productCategory);
    },
    // 订单金额
    amount(row) {
      var orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var totalAmount = 0;
        for (let i = 0; i < orderBOList.length; i++) {
          totalAmount = totalAmount + orderBOList[i].amount;
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType != 3) {
              break;
            }
          } else {
            break;
          }
        }
        return totalAmount;
      }
      return row.amount;
    },
    // 实收金额
    actualAmount(row) {
      var orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var totalAmount = 0;
        for (let i = 0; i < orderBOList.length; i++) {
          totalAmount = totalAmount + orderBOList[i].actualAmount;
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType != 3) {
              break;
            }
          } else {
            break;
          }
        }
        return totalAmount;
      }
      return row.actualAmount;
    },
    formerAmount(row) {
      var orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var totalAmount = 0;
        for (let i = 0; i < orderBOList.length; i++) {
          totalAmount = totalAmount + orderBOList[i].formerAmount;
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType != 3) {
              break;
            }
          } else {
            break;
          }
        }
        return totalAmount;
      }
      return row.formerAmount;
    },
    // 用户数
    orderNumber(row) {
      let orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var orderNumberTemp = 0;
        for (let i = 0; i < orderBOList.length; i++) {
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType == 3) {
              orderNumberTemp = orderNumberTemp + orderBOList[i].orderNumber;
            } else {
              if (orderNumberTemp != 0) {
                orderNumberTemp = orderNumberTemp + orderBOList[i].orderNumber;
              } else {
                orderNumberTemp = orderBOList[i].orderNumber;
              }
              break;
            }
          }
        }
        return orderNumberTemp;
      }
      return row.orderNumber;
    },
    // 年限
    orderTerm(row) {
      let orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var orderTermTemp;
        for (let i = 0; i < orderBOList.length; i++) {
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType != 3) {
              orderTermTemp = orderBOList[i].orderTerm;
              break;
            }
          } else {
            orderTermTemp = orderBOList[i].orderTerm;
            break;
          }
        }
        if (!orderTermTemp) {
          return orderBOList[0].orderTerm;
        }
        return orderTermTemp;
      }
      return row.orderTerm;
    },
    statusFormatter(row) {
      let notPayed = row.notPayed;
      let payed = row.payed;
      let statusTotal = row.statusTotal;
      if (statusTotal == notPayed) {
        return "未交付";
      }
      if (statusTotal == payed) {
        return "已交付";
      }
      return "部分交付";
    },
    expireTimeFormatter(row) {
      var orderBOList = row.orderBOList;
      if (orderBOList && orderBOList.length > 0) {
        var expireTimeTemp;
        for (let i = 0; i < orderBOList.length; i++) {
          if (orderBOList[i].productCategory == 1) {
            if (orderBOList[i].orderType != 3) {
              expireTimeTemp = orderBOList[i].expireTime;
              break;
            }
          } else {
            expireTimeTemp = orderBOList[i].expireTime;
            break;
          }
        }
        if (!expireTimeTemp) {
          return orderBOList[0].expireTime;
        }
        return expireTimeTemp;
      }
      return row.expireTime;
    },
    // 详情
    onDetails(row) {
      if (this.btnP.closedDetails) {
        this.drawer = true;
        setTimeout(() => {
          this.$refs.details.getData(row);
        }, 50);
      }
    },
    handleDetails() {
      this.drawer = false;
      this.getTableData();
    },
    // 清空
    searchEmpty() {
      this.field = "";
      this.fieldVal = "";
      this.primaryEmailBrandVal = "";
      this.customerStatusVal = "";
      this.createTime = "";
      this.createDate = [];
      this.productCategory = "";
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.customer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .search-box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    span {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      margin: 0 16px;
    }
    .search-one {
      padding: 10px 0 12px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        button {
          background: #2370eb;
          border-radius: 2px;

          margin-right: 16px;
        }
      }
    }
    .search-two {
      padding-bottom: 10px;
    }
  }
  .table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    .add {
      border-color: #d9d9d9;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    button {
      font-size: 12px;
      padding: 0 7px 0 12px;
      height: 24px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #2370eb;
      cursor: pointer;
      color: #2370eb;
      margin-left: 16px;
    }

    button:hover {
      background: #2370eb;
      color: #ffffff;
      border-color: #2370eb;
    }
  }
  .page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Times {
    display: inline-block;
    width: 20px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    background: #ff760f;
    border-radius: 63px 63px 63px 1px;
    text-align: center;
  }
  .Timess {
    background: #ff9385;
    margin-left: 3px;
  }
}
</style>
