import http from '@/utils/http.js'

// 我的已成交客户
export function meCustomerList(data) {
    return http({
        url: "/jasoboss/customer/traded/list/my",
        method: "post",
        data
    })
}


// 部门已成交客户
export function dempCustomerList(data) {
    return http({
        url: "/jasoboss/customer/traded/list/department",
        method: "post",
        data
    })
}
// 已成交的订单
export function payedList(data) {
    return http({
        url: "/jasoboss/order/list/payed",
        method: "post",
        data
    })
}
// 已成交的订单
export function topBrief(data) {
    return http({
        url: "/jasoboss/order/list/top/brief",
        method: "post",
        data
    })
}